import classNames from 'classnames';
import React from 'react';

import { CheckCircleIcon, ClockIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid';

export function ContactStatusIcon({
  contact,
  className,
}: {
  contact: {
    reportState?: {
      submittedAt?: Date;
      lastEngagedAt?: Date;
    };
  };
  className?: string;
}) {
  const { reportState } = contact;
  const iconData = reportState?.submittedAt
    ? { icon: CheckCircleIcon, color: 'text-green-500' }
    : reportState?.lastEngagedAt
    ? { icon: ClockIcon, color: 'text-blue-500' }
    : { icon: QuestionMarkCircleIcon, color: 'text-gray-500' };

  return <iconData.icon className={classNames(iconData.color, className)} />;
}
