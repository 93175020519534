import { gql } from '@apollo/client';

import { CaseData } from '../CaseView/types';

export const GET_CASES = gql`
  query getAdminCases {
    processing: adminCases(filter: processing) {
      ...AdminCaseFragment
    }
    processing_self: adminCases(filter: processing_self) {
      ...AdminCaseFragment
    }
    assigned_self: adminCases(filter: assigned_self) {
      ...AdminCaseFragment
    }
    assigned_others: adminCases(filter: assigned_others) {
      ...AdminCaseFragment
    }
    completed: adminCases(filter: completed) {
      ...AdminCaseFragment
    }
  }

  fragment AdminCaseFragment on Case {
    id
    status
    externalId
    externalType
    investigationBeganAt
    investigationScheduledToEndAt
    investigationReadyToDispatchAt
    assignedTo

    contacts {
      id
      name
      investigationOptions {
        shouldContact
      }
      reportState {
        firstContactedAt
        lastContactedAt
        firstEngagedAt
        lastEngagedAt
        submittedAt
      }
    }
  }
`;

export const CREATE_NEW_INVESTIGATION = gql`
  mutation CreateInvestigationCase($externalDocumentBase64: String!) {
    createInvestigationCase(externalDocumentBase64: $externalDocumentBase64) {
      id
      externalId

      contacts {
        id
        name
        phoneNumber
        types
        caseVehicle {
          id
          make
          model
          isCarrierInsuredVehicle
        }
        investigationOptions {
          shouldContact
          repairCoordinationEligible
        }
      }

      vehicles {
        id
        make
        model
      }
    }
  }
`;

export interface CreateNewInvestigationData {
  createInvestigationCase: CaseData;
}
