import classNames from 'classnames';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';

import {
  AnnotationIcon, ClipboardListIcon, ClockIcon, PhotographIcon
} from '@heroicons/react/solid';

import { KnockNotificationFeed } from '../../services/knock';

export default function Inbox() {
  return (
    <div className="bg-white rounded-md shadow flex flex-col items-stretch overflow-hidden">
      <KnockNotificationFeed
        inlineStyle={{ height: 300 }}
        displayMode="inline"
      />
    </div>
  );
}
