import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryStringParsed } from '@frontend/hooks';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

import { useUsers } from '../../hooks/useUsers';
import { ContactStatusIcon } from '../CaseView/components/partials/ContactStatusIcon';
import { GET_CASES } from './queries';
import TableTabs from './TableTabs';
import { AdminCase, AdminCaseFilter, AdminCaseQueryResponse } from './types';

// Make all fromNow() use hours for up to 120 hrs
moment.relativeTimeThreshold('h', 120);

const SESSION_STORAGE_KEY = 'assured$$CaseDashboardTableTab';

export default function Table() {
  const { user } = useAuth0();
  const isGod = !!user?.['https://assured.claims/god'];

  const location = useLocation();
  const history = useHistory();

  const { users } = useUsers();

  const { data, loading } = useQuery<AdminCaseQueryResponse>(GET_CASES, {
    pollInterval: 1000 * 10, // every 10s
    fetchPolicy: 'cache-and-network',
  });

  const [selectedTab, setSelectedTab] = useState<AdminCaseFilter>(
    window.sessionStorage[SESSION_STORAGE_KEY] || AdminCaseFilter.assigned_self,
  );

  useEffect(() => {
    window.sessionStorage[SESSION_STORAGE_KEY] = selectedTab;
  }, [selectedTab]);

  return (
    <div>
      <TableTabs
        data={data}
        selectedTab={selectedTab}
        onSelectTab={t => setSelectedTab(t)}
      />

      <div className="mt-6 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-4 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="w-2/12 px-4 py-3 pl-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Claim Identifier
                    </th>
                    <th
                      scope="col"
                      className="w-3/12 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Classification
                    </th>
                    <th
                      scope="col"
                      className="w-2/12 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Contacting
                    </th>
                    <th
                      scope="col"
                      className="w-2/12 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="w-2/12 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Assignee
                    </th>
                    {/* <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Last Activity
                    </th> */}
                    <th
                      scope="col"
                      className="w-1/12 relative px-4 py-3 pr-6"
                    ></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data ? (
                    data[selectedTab].length === 0 ? (
                      <tr>
                        <td
                          className="px-4 py-8 text-center text-sm font-medium text-gray-500"
                          colSpan={100}
                        >
                          No claims found
                        </td>
                      </tr>
                    ) : (
                      data[selectedTab].map(currentCase => (
                        <tr
                          key={currentCase.id}
                          className="group cursor-pointer hover:bg-gray-50 relative"
                        >
                          <td className="w-2/12 px-4 py-4 pl-6 whitespace-nowrap text-sm font-medium text-gray-900">
                            <Link
                              to={`/cases/${currentCase.id}`}
                              className="absolute inset-0"
                            />
                            {currentCase.externalId}
                          </td>
                          <td className="w-3/12 px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                            {currentCase.externalType}
                          </td>
                          <td className="w-2/12 px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                            {(() => {
                              const output = currentCase.contacts
                                ?.filter(
                                  c => c.investigationOptions?.shouldContact,
                                )
                                .map((c, i, arr) => {
                                  const isLast = i === arr.length - 1;

                                  return (
                                    <div
                                      key={c.name}
                                      className={classNames(
                                        'flex items-center',
                                        !isLast && 'mb-1',
                                      )}
                                    >
                                      <ContactStatusIcon
                                        contact={c}
                                        className="w-4 h-4 mr-1 flex-shrink-0"
                                      />
                                      <span className="leading-tight">
                                        {c.name}
                                      </span>
                                    </div>
                                  );
                                });
                              return output?.length ? output : 'No one';
                            })()}
                          </td>
                          <td className="w-2/12 px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                            {currentCase.status === 'PROCESSING' ? (
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                Ready within{' '}
                                {moment(
                                  currentCase.investigationScheduledToEndAt,
                                ).fromNow(true)}
                              </span>
                            ) : currentCase.status === 'COMPLETED' ? (
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                Archived
                              </span>
                            ) : (
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                Ready
                              </span>
                            )}
                            {isGod && currentCase.status === 'PROCESSING' ? (
                              <div className="text-xs mt-1 leading-none">
                                {currentCase.investigationReadyToDispatchAt ? (
                                  <>
                                    {
                                      // <div className="flex items-center text-gray-400">
                                      //   <CheckCircleIcon className="w-4 h-4 text-gray-400" />
                                      //   <div className="ml-1">Dispatched</div>
                                      // </div>
                                    }
                                  </>
                                ) : (
                                  <div className="flex items-center text-blue-500 font-medium">
                                    <ExclamationCircleIcon className="w-4 h-4" />
                                    <div className="ml-1">
                                      Not yet dispatched
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : null}
                          </td>
                          <td className="w-2/12 px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                            {currentCase.assignedTo
                              ? users.find(
                                  u => u.user_id === currentCase.assignedTo,
                                )?.name
                              : 'Not yet assigned'}
                          </td>
                          {/* <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                            {claim.last_activity}
                          </td> */}
                          <td className="w-1/12 px-4 py-4 pr-6 whitespace-nowrap text-right text-sm font-medium">
                            <span className="ml-4 text-indigo-600 hover:text-indigo-800 group-hover:text-indigo-800">
                              View
                            </span>
                          </td>
                        </tr>
                      ))
                    )
                  ) : (
                    <tr>
                      <td
                        className="px-4 py-8 text-center text-sm font-medium text-gray-500"
                        colSpan={100}
                      >
                        Loading claims...
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
