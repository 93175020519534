import classNames from 'classnames';
import React, { Fragment, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router';

import { useMutation } from '@apollo/client';
import { DocumentAddIcon } from '@heroicons/react/outline';

import ConfigureInvestigationModal from '../CaseView/ConfigureInvestigationModal';
import ErrorModal from '../shared/ErrorModal';
import { CREATE_NEW_INVESTIGATION, CreateNewInvestigationData } from './queries';

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(
        (reader.result as string).substring(
          (reader.result as string).indexOf(',') + 1,
        ),
      );
    reader.onerror = error => reject(error);
  });

export default function NewInvestigation() {
  const history = useHistory();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [createInvestigation, { loading, data }] =
    useMutation<CreateNewInvestigationData>(CREATE_NEW_INVESTIGATION, {
      onCompleted: () => {},
      onError: e => {
        const errorBlob = JSON.stringify(e);
        if (errorBlob.indexOf('EXISTING_CASE') !== -1) {
          const existingCaseId = errorBlob.match(
            /\[EXISTING_CASE:(.*?)\]/,
          )?.[1];
          window.alert(
            "An investigation for this claim is already in progress. We'll redirect you there now.",
          );
          history.push(`/cases/${existingCaseId}`);
          return;
        }
        setErrorText('');
        setShowErrorModal(true);
        setShowModal(false);
      },
    });
  const pendingCase = data?.createInvestigationCase;
  // ||
  //   (window.localStorage['pendingCase'] &&
  //     JSON.parse(window.localStorage['pendingCase']));
  // if (pendingCase) {
  //   window.localStorage['pendingCase'] = JSON.stringify(pendingCase);
  // }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async acceptedFiles => {
      const file = acceptedFiles[0];
      if (file) {
        try {
          setShowModal(true);
          createInvestigation({
            variables: { externalDocumentBase64: await toBase64(file) },
          });
        } catch (e) {
          setErrorText(
            'Failed to prepare file. Please make sure you are using a modern browser.',
          );
          setShowErrorModal(true);
        }
      } else {
        setErrorText(
          'Invalid file. Please make sure you are uploading a Claim Export PDF and try again.',
        );
        setShowErrorModal(true);
      }
    },
    accept: 'application/pdf',
    multiple: false,
  });

  return (
    <>
      <ConfigureInvestigationModal
        show={showModal}
        onClose={() => setShowModal(false)}
        isNew
        targetCase={pendingCase}
      />
      <ErrorModal
        show={showErrorModal}
        message={errorText}
        onClose={() => {
          setShowErrorModal(false);
        }}
      />
      <div
        className="mt-4 rounded-md shadow bg-white px-6 py-6 flex flex-col"
        style={{ minHeight: 300 }}
      >
        <div className="text-gray-600 text-sm">
          Upload a Claim Export PDF to begin a new investigation. You'll confirm
          specific individuals and investigation focus in the next step.
        </div>

        <div
          {...getRootProps()}
          className={classNames(
            'mt-4 flex-1 flex flex-col justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md bg-gray-50 cursor-pointer group',
            isDragActive ? 'bg-indigo-50' : 'hover:bg-gray-100',
          )}
        >
          <input {...getInputProps()} />
          <div className="space-y-1 text-center">
            <DocumentAddIcon className="mx-auto h-12 w-12 mb-3 text-gray-400 group-hover:text-indigo-500" />
            <div className="text-sm text-gray-600">
              {isDragActive ? (
                <span className="font-medium text-indigo-600">
                  Drop the file here...
                </span>
              ) : (
                <>
                  <span className="font-medium text-indigo-600">
                    Upload a file
                  </span>{' '}
                  or drag and drop
                </>
              )}
            </div>
            <p className="text-xs text-gray-500">PDF up to 20MB</p>
          </div>
        </div>
      </div>
    </>
  );
}
