import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import Popover from 'react-tiny-popover';

import { useMutation, useQuery } from '@apollo/client';
import Button from '@common/components/Button';
import Modal from '@common/components/Modal';
import { formatPhoneNumber } from '@frontend/components/ClaimView';
import PageLoader from '@frontend/components/PageLoader';
import { Switch } from '@headlessui/react';
import { PlusCircleIcon, XIcon } from '@heroicons/react/outline';
import { PencilAltIcon, UserCircleIcon } from '@heroicons/react/solid';

import { GET_CASES } from '../CaseDashboard/queries';
import { enumToLabel } from '../ClaimView/components/helpers/util';
import Select from '../shared/Select';
import {
  LOOKUP_PHONE_NUMBERS, UPDATE_INVESTIGATION_SETTINGS, UpdateInvestigationSettingsData
} from './queries';
import { CaseData } from './types';

interface InvestigationSettings {
  [k: string]: {
    shouldContact: boolean;
    phoneNumber?: string;
  };
}

interface NewCaseContact {
  key: string;
  name: string;
  phoneNumber: string;
  types: string;
  caseVehicleId?: string;
}

export default function ConfigureInvestigationModal({
  show,
  onClose,
  isNew,
  targetCase,
}: {
  show: boolean;
  onClose: () => void;
  isNew?: boolean;
  targetCase?: CaseData;
}) {
  const [newContacts, setNewContacts] = useState<NewCaseContact[]>([]);
  const [investigationSettings, setInvestigationSettings] =
    useState<InvestigationSettings>({});

  const [updateInvestigationSettingsForCase, { loading, data }] =
    useMutation<UpdateInvestigationSettingsData>(
      UPDATE_INVESTIGATION_SETTINGS,
      {
        onCompleted: () => {
          // FIXME: Popup success thing
          onClose();
        },
        onError: e => {
          window.alert(
            `Failed to save investigation settings: ${JSON.stringify(
              e,
            )}. Please contact support@assured.claims.`,
          );
        },
        refetchQueries: [
          {
            // Ensure that firing an investigation will trigger the Case
            // table to update immediately with the new investigation.
            query: GET_CASES,
          },
        ],
      },
    );

  const isValidPhoneNumber = (number: string | null): number is string =>
    number?.length === `+10001112222`.length;
  const prepareUserEnteredPhoneNumber = (userInput: string) =>
    `+1${userInput.replace(/[^0-9]/g, '')}`;

  const targetPhoneNumbers = (targetCase?.contacts || [])
    .map(c => {
      if (!investigationSettings[c.id]?.shouldContact) {
        return null;
      }
      if (investigationSettings[c.id]?.phoneNumber) {
        return prepareUserEnteredPhoneNumber(
          investigationSettings[c.id].phoneNumber!,
        );
      }
      return c.phoneNumber;
    })
    .concat(newContacts.map(c => prepareUserEnteredPhoneNumber(c.phoneNumber)))
    .filter(isValidPhoneNumber);

  const { data: phoneNumberLookupData, loading: loadingPhoneNumberLookup } =
    useQuery(LOOKUP_PHONE_NUMBERS, {
      errorPolicy: 'all', // partial data if some numbers fail
      skip: targetPhoneNumbers.length === 0,
      variables: {
        phoneNumbers: targetPhoneNumbers,
      },
    });

  const getPhoneNumberError = (phoneNumber: string) => {
    const targets = targetPhoneNumbers.filter(p => p === phoneNumber);
    if (targets.length === 0) {
      // not checking
      return null;
    }
    if (targets.length > 1) {
      // instructed to check multiple times => error
      return `Multiple active contacts have the same phone number. Please edit the phone number or disable all but one of the contacts.`;
    }

    if (!phoneNumberLookupData?.lookupPhoneNumbers) {
      // Lookup not run
      return null;
    }

    const allData = phoneNumberLookupData.lookupPhoneNumbers as any[];
    const numberData = allData.find(d => d?.phoneNumber === phoneNumber);

    if (!numberData) {
      // Not found
      return `This phone number is invalid. Please try another number or disable this contact.`;
    }

    if (numberData.type === 'landline') {
      // Landline
      return `This phone number is a landline and cannot receive texts. Please enter a valid mobile number or disable this contact.`;
    }
  };

  const hasAnyPhoneNumberErrors = targetPhoneNumbers.some(p =>
    getPhoneNumberError(p),
  );

  useEffect(() => {
    let settings: InvestigationSettings = {};
    targetCase?.contacts.forEach(c => {
      settings[c.id] = {
        shouldContact:
          typeof c.investigationOptions?.shouldContact !== 'undefined'
            ? c.investigationOptions.shouldContact
            : true,
      };
    });
    setInvestigationSettings(settings);
    setNewContacts([]);
  }, [show, targetCase]);

  const contacts = targetCase?.contacts.slice(0);

  return (
    <Modal
      isShowing={show}
      hideActionButtons
      onClose={() => {
        setNewContacts([]);
        onClose();
      }}
      title={
        targetCase
          ? `${
              isNew ? 'Launch investigation' : 'Edit investigation'
            } for claim ${targetCase.externalId}`
          : `Preparing investigation...`
      }
    >
      <div className={classNames('mt-4')}>
        {targetCase && contacts ? (
          <>
            <div className="text-sm text-gray-700">
              {isNew ? (
                <>
                  Use the toggles to control who will be contacted. When you're
                  ready to assign the investigation to Assured, click "Start
                  investigation".
                </>
              ) : (
                <>
                  Update the investigation settings below. Adding a new
                  individual will reset the 60 hour timer on the investigation.
                </>
              )}
            </div>
            <div className="flow-root mt-7 mb-10">
              <ul role="list" className="-mt-2 -mb-5 divide-y divide-gray-200">
                <li></li>
                {contacts.map(contact => {
                  const selfSettings = investigationSettings[contact.id];
                  const enabled = selfSettings?.shouldContact;
                  const textColor = contact.caseVehicle?.isCarrierInsuredVehicle
                    ? 'text-blue-500'
                    : 'text-gray-500';

                  return (
                    <li key={contact.id} className="py-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <UserCircleIcon
                            className={classNames(
                              'h-10 w-10',
                              textColor,
                              !enabled && 'opacity-50',
                            )}
                          />
                        </div>
                        <div
                          className={classNames(
                            'flex-1 min-w-0',
                            !enabled && 'opacity-50',
                          )}
                        >
                          <p className="text-sm font-medium text-gray-900 truncate">
                            {contact.name}
                          </p>
                          <p className="text-sm text-gray-500 truncate">
                            {contact.caseVehicle
                              ? `${contact.caseVehicle.make} ${contact.caseVehicle.model}`
                              : 'No vehicle'}
                            {contact.caseVehicle?.isCarrierInsuredVehicle ? (
                              <span className={classNames(textColor)}>
                                {' '}
                                (Insured vehicle)
                              </span>
                            ) : (
                              ''
                            )}
                          </p>
                          {selfSettings?.phoneNumber ? (
                            <div>
                              <InputMask
                                mask="(999) 999-9999"
                                type="text"
                                value={selfSettings?.phoneNumber || ''}
                                onChange={e => {
                                  const phoneNumber = e.target.value;
                                  setInvestigationSettings(settings => ({
                                    ...settings,
                                    [contact.id]: {
                                      ...settings[contact.id],
                                      phoneNumber,
                                    },
                                  }));
                                }}
                                className="my-1 shadow-sm focus:outline-none focus:shadow-outline-indigo focus:border-indigo-500 block w-36 text-xs border border-gray-300 rounded-md py-1 px-2"
                                placeholder="(123) 456-7890"
                              />
                              {selfSettings?.shouldContact &&
                              getPhoneNumberError(
                                prepareUserEnteredPhoneNumber(
                                  selfSettings.phoneNumber,
                                ),
                              ) ? (
                                <div className="mt-0.5 text-xs font-medium leading-tight text-red-500">
                                  {getPhoneNumberError(
                                    prepareUserEnteredPhoneNumber(
                                      selfSettings.phoneNumber,
                                    ),
                                  )}
                                </div>
                              ) : null}
                            </div>
                          ) : (
                            <>
                              <p className="text-sm text-gray-500 truncate">
                                {formatPhoneNumber(contact.phoneNumber) ||
                                  'No phone number'}
                                <PencilAltIcon
                                  className="cursor-pointer inline-block w-4 h-4 text-gray-400 hover:text-blue-500 ml-2"
                                  style={{ verticalAlign: -2.5 }}
                                  onClick={() => {
                                    setInvestigationSettings(settings => ({
                                      ...settings,
                                      [contact.id]: {
                                        ...settings[contact.id],
                                        phoneNumber: formatPhoneNumber(
                                          contact.phoneNumber,
                                        ),
                                      },
                                    }));
                                  }}
                                />
                              </p>
                              {selfSettings?.shouldContact &&
                              getPhoneNumberError(contact.phoneNumber) ? (
                                <div className="mt-0.5 text-xs font-medium leading-tight text-red-500">
                                  {getPhoneNumberError(contact.phoneNumber)}
                                </div>
                              ) : null}
                            </>
                          )}
                          <p className="mt-0.5">
                            {contact.types.map(type => (
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 mr-2">
                                {enumToLabel(type)}
                              </span>
                            ))}
                          </p>
                        </div>
                        <div>
                          <Switch
                            checked={enabled}
                            onChange={() => {
                              setInvestigationSettings(settings => ({
                                ...settings,
                                [contact.id]: {
                                  ...settings[contact.id],
                                  shouldContact: !enabled,
                                },
                              }));
                            }}
                            className={classNames(
                              enabled ? 'bg-indigo-600' : 'bg-gray-200',
                              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                            )}
                          >
                            <span
                              className={classNames(
                                enabled ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                              )}
                            >
                              <span
                                className={classNames(
                                  enabled
                                    ? 'opacity-0 ease-out duration-100'
                                    : 'opacity-100 ease-in duration-200',
                                  'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                                )}
                                aria-hidden="true"
                              >
                                <svg
                                  className="h-3 w-3 text-gray-400"
                                  fill="none"
                                  viewBox="0 0 12 12"
                                >
                                  <path
                                    d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                              <span
                                className={classNames(
                                  enabled
                                    ? 'opacity-100 ease-in duration-200'
                                    : 'opacity-0 ease-out duration-100',
                                  'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                                )}
                                aria-hidden="true"
                              >
                                <svg
                                  className="h-3 w-3 text-indigo-600"
                                  fill="currentColor"
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                </svg>
                              </span>
                            </span>
                          </Switch>
                        </div>
                      </div>
                    </li>
                  );
                })}
                {newContacts.map((contact, idx) => {
                  const update = (key: keyof typeof contact, value: any) => {
                    setNewContacts(contacts => {
                      return contacts.map((c, _idx) => {
                        let result = { ...c };
                        if (idx === _idx) {
                          result[key] = value;
                        }
                        return result;
                      });
                    });
                  };
                  return (
                    <li className="py-4 flex items-center">
                      <div className="flex-shrink-0 mr-4">
                        <UserCircleIcon
                          className={classNames('h-10 w-10', 'text-gray-500')}
                        />
                      </div>
                      <div className="flex-1 grid grid-cols-2 gap-3 items-start">
                        <input
                          type="text"
                          value={contact.name}
                          onChange={e => update('name', e.target.value)}
                          className="shadow-sm focus:outline-none focus:shadow-outline-indigo focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md py-1 px-2"
                          placeholder="John Doe"
                        />
                        <div>
                          <InputMask
                            mask="(999) 999-9999"
                            type="text"
                            value={contact.phoneNumber}
                            onChange={e =>
                              update('phoneNumber', e.target.value)
                            }
                            className="shadow-sm focus:outline-none focus:shadow-outline-indigo focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md py-1 px-2"
                            placeholder="(123) 456-7890"
                          />
                          {getPhoneNumberError(
                            prepareUserEnteredPhoneNumber(contact.phoneNumber),
                          ) ? (
                            <div className="mt-0.5 text-xs font-medium leading-tight text-red-500">
                              {getPhoneNumberError(
                                prepareUserEnteredPhoneNumber(
                                  contact.phoneNumber,
                                ),
                              )}
                            </div>
                          ) : null}
                        </div>
                        <div className="">
                          <Select
                            clearable
                            placeholder="Not in a vehicle"
                            onChange={v => update('caseVehicleId', v)}
                            value={contact.caseVehicleId}
                            options={targetCase.vehicles.map((v: any) => ({
                              value: v.id,
                              label: `${v.make} ${v.model}`,
                            }))}
                          />
                        </div>
                        <div className="">
                          <Select
                            placeholder="Select role"
                            onChange={v => update('types', v)}
                            value={contact.types}
                            options={[
                              {
                                value: 'PASSENGER',
                                label: `Passenger`,
                              },
                              {
                                value: 'WITNESS',
                                label: `Witness`,
                              },
                              {
                                value: 'DRIVER',
                                label: `Driver`,
                              },
                            ]}
                          />
                        </div>
                      </div>
                      <button
                        className="ml-4"
                        onClick={() =>
                          setNewContacts(c =>
                            c.filter(c => c.key !== contact.key),
                          )
                        }
                      >
                        <XIcon className="w-4 h-4 text-gray-600" />
                      </button>
                    </li>
                  );
                })}
                <li className="py-4 flex items-center justify-center">
                  <button
                    type="button"
                    className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() =>
                      setNewContacts(c =>
                        c.concat([
                          {
                            key: Math.random().toString(),
                            name: '',
                            phoneNumber: '',
                            types: '',
                            caseVehicleId: undefined,
                          },
                        ]),
                      )
                    }
                  >
                    <PlusCircleIcon className="w-4 h-4 mr-1" />
                    <span>Add new contact</span>
                  </button>
                </li>
              </ul>
            </div>
            {/* <div className="text-xs my-2 break-all">
              {JSON.stringify(targetCase)}
            </div> */}
            <div className="mt-5 flex gap-4">
              {!isNew ? (
                <Button
                  disabled={loading}
                  onClick={() => onClose()}
                  buttonColor="white"
                >
                  Cancel
                </Button>
              ) : null}
              <Button
                buttonColor="indigo"
                disabled={
                  loading || loadingPhoneNumberLookup || hasAnyPhoneNumberErrors
                }
                onClick={() =>
                  updateInvestigationSettingsForCase({
                    variables: {
                      caseId: targetCase.id,
                      settings: Object.keys(investigationSettings).map(
                        contactId => {
                          const settings = {
                            ...investigationSettings[contactId],
                          };
                          if (settings.phoneNumber) {
                            settings.phoneNumber =
                              prepareUserEnteredPhoneNumber(
                                settings.phoneNumber,
                              );
                          }
                          return { contactId, ...settings };
                        },
                      ),
                      addNewContacts: newContacts.map(newContact => {
                        return {
                          name: newContact.name,
                          phoneNumber: prepareUserEnteredPhoneNumber(
                            newContact.phoneNumber,
                          ),
                          types: [newContact.types],
                          caseVehicleId: newContact.caseVehicleId,
                        };
                      }),
                    },
                  })
                }
              >
                {hasAnyPhoneNumberErrors
                  ? 'Fix phone numbers to continue'
                  : isNew
                  ? loading
                    ? 'Starting investigation...'
                    : 'Start investigation'
                  : loading
                  ? 'Updating...'
                  : 'Update investigation'}
              </Button>
            </div>
          </>
        ) : (
          <>
            <PageLoader style={{ height: 60 }} />
            <div className="px-4 pt-5 text-center text-sm font-medium text-gray-500">
              Parsing PDF... This may take up to 30 seconds.
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
