import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import Layout from '../shared/Layout';
import Inbox from './Inbox';
import NewInvestigation from './NewInvestigation';
import Table from './Table';

const Heading: React.FC<{}> = ({ children }) => {
  return (
    <div className="h-6 relative">
      <div className="absolute top-3 w-full h-px bg-gray-300"></div>
      <h3 className="absolute left-0 top-0 h-6 text-lg leading-6 font-medium text-gray-900 pr-2 bg-gray-50">
        {children}
      </h3>
    </div>
  );
};

export default function ClaimDashboard() {
  const { user } = useAuth0();
  const isDataRentryMode = !!user?.['https://assured.claims/data_rentry_mode'];

  return (
    <>
      <Layout
        navigation={[{ current: true, name: 'Intelligent Investigation' }]}
      >
        <main className="mt-12 max-w-7xl mx-auto px-8 flex flex-col pb-12">
          {!isDataRentryMode ? (
            <div className="grid grid-cols-6 gap-8">
              <div className="col-span-2">
                <Heading>New investigation</Heading>
                <NewInvestigation />
              </div>
              <div className="col-span-4">
                <Heading>Your inbox</Heading>
                <div className="mt-4">
                  <Inbox />
                </div>
              </div>
            </div>
          ) : null}
          <div className={isDataRentryMode ? '' : 'mt-8'}>
            <Heading>Investigations</Heading>
            <div className="mt-2">
              <Table />
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
}
