export interface AdminCase {
  id: string;
  status: string;
  externalId: string;
  assignedTo?: string;
  externalType: string;
  investigationBeganAt?: Date;
  investigationScheduledToEndAt?: Date;
  investigationReadyToDispatchAt?: Date;
  contacts: {
    name: string;
    investigationOptions?: {
      shouldContact: boolean;
    };
    reportState?: {
      firstContactedAt?: Date;
      lastContactedAt?: Date;
      firstEngagedAt?: Date;
      lastEngagedAt?: Date;
      submittedAt?: Date;
    };
  }[];
}

export enum AdminCaseFilter {
  processing = 'processing',
  processing_self = 'processing_self',
  assigned_self = 'assigned_self',
  assigned_others = 'assigned_others',
  completed = 'completed',
}

export type AdminCaseQueryResponse = {
  [key in AdminCaseFilter]: AdminCase[];
};
