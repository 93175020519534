import React from 'react';

import Modal from '@common/components/Modal';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

interface ErrorModalProps {
  show: boolean;
  message?: string;
  onClose: () => void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ show, message, onClose }) => {
  return (
    <Modal title="" isShowing={show} subtleCloseButton onClose={onClose}>
      <div className="flex flex-col items-center">
        <ExclamationCircleIcon className="w-12 h-12 text-red-500" />
        <div className="mt-3 text-gray-700 text-center text-sm">
          {message ? (
            message
          ) : (
            <>
              Sorry! Something went wrong. Please try again, or{' '}
              <a
                onClick={e => {
                  e.preventDefault();
                  (window as any).Intercom('showNewMessage');
                }}
                href="#"
                className="underline whitespace-no-wrap hover:opacity-75"
              >
                contact support
              </a>{' '}
              if the problem persists.
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
